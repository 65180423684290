@use "variables" as *;

.wrapper {
  padding-bottom: $spacing-1;
}

.label {
  display: block;
  padding: 0 0 $spacing-1;
  color: $content-secondary-color;
}

.tagSpacer {
  margin-right: $spacing-half;
  margin-bottom: $spacing-1;
}

.tag {
  cursor: pointer;
}
