@use "variables" as *;

.wrapper {
  display: flex;
  margin-top: $spacing-1;
  margin-bottom: $spacing-2;
}

.detailsButton {
  margin-left: $spacing-2;
}
