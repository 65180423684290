// ------------------------------------
// responsive utilities
// ------------------------------------
/// @access private
// ------------------------------------

@use 'sass:list';
@use 'sass:map';
@import '../config/all';

// ----------------------------------------------
// on-bp mixin
// ----------------------------------------------
// general breakpoint mixin
/// @param {string} $breakpoint - one of the map keys in breakpoints list
/// @param {string} $only - indicates that style set should be applied for this breakpoint only, restricting the other ones with max-width
// ----------------------------------------------

@mixin on-bp($breakpoint: md, $only: false) {
  // gets the current breakpoint index
  $bp-index: list.index(map.keys($breakpoints), $breakpoint);
  // gets index of the upper limit
  $bp-limit-index: list.index(map.keys($breakpoints), $config-breakpoint-limit);

  @if (not $bp-limit-index or $bp-index <= $bp-limit-index) {
    @if ($only == 'only') {
      // checks if we have a greater breakpoint, skips property if not
      @if ($bp-index < list.length(map.keys($breakpoints))) {
        // gets the next breakpoint in the list
        $bp-next: list.nth(map.keys($breakpoints), $bp-index + 1);
        @media (min-width: map.get($breakpoints, $breakpoint)) and (max-width: (map.get($breakpoints, $bp-next) - 1px)) {
          @content;
        }
      } @else {
        @warn 'No greater breakpoint found for key: `#{$breakpoint}` in $breakpoints map. Property omitted.';
      }
    } @else {
      @media (min-width: map.get($breakpoints, $breakpoint)) {
        @content;
      }
    }
  }
}

// ----------------------------------------------
// on-range mixin
// ----------------------------------------------
// range breakpoint mixin
/// @param {string} $from - indicates lower breakpoint limit
/// @param {string} $to - indicates greater breakpoint limit
// ----------------------------------------------

@mixin on-range($from, $to) {
  $min: map.get($breakpoints, $from);
  $max: map.get($breakpoints, $to);

  @media (min-width: $min) and (max-width: ($max - 1px)) {
    @content;
  }
}
