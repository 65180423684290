@use "variables" as *;

@mixin clickableBox {
  cursor: pointer;
  text-decoration: none;
  color: $content-primary-color;

  &:visited {
    color: $content-primary-color;
  }
}
