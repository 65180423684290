@use "variables" as *;

@mixin list-item-grid {
  display: grid;
  align-items: center;
  width: calc(100% - #{$spacing-6});

  > div {
    padding: 0 $spacing-2;
  }
}
