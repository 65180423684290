@use "variables" as *;

.buttonWrapper {
  margin-top: $spacing-8;
  margin-bottom: $spacing-4;
}

.flex {
  display: flex;
  justify-content: space-between;
}
