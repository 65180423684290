@use "variables" as *;

.wrapper {
  margin-top: $spacing-1;
  display: flex;
}

.scheduleName {
  margin: 0 0 20px $spacing-2;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 70%;
  overflow: hidden;
}
