@use "variables" as *;

@mixin infobox {
  padding: $spacing-2;
  &::before {
    mask-size: $spacing-4;
    width: $spacing-4;
    height: $spacing-4;
  }
}
