@use "variables" as *;

@mixin tagOutline {
  height: $spacing-5;
  border-radius: calc(#{$spacing-5}/ 2);
}

.wrapper {
  grid-row: 1/2;
  align-self: center;
}

.tag {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  @include tagOutline;
}

.periodInPast {
  width: 50%;
  justify-self: start;
}

.periodInFuture {
  width: 50%;
  justify-self: end;
}

.cutOffRight {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding-right: 0 !important;
}

.cutOffLeft {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding-left: 0 !important;
}

@mixin weekend {
  background-color: #e1e1e160;
}

@mixin highlighted-cell {
  background-color: $highlight-color;
}
