// ------------------------------------
// Text alignment helpers
// ------------------------------------

@import '../common/config/all';
@import '../common/utils/exports';

@include exports('helpers-alignment') {
  #{$config-class-prefix} {

    // applies different types of text alignment

    &has-text-centered {
      text-align: center;
    }

    &has-text-left {
      text-align: left;
    }

    &has-text-right {
      text-align: right;
    }
  }
}
