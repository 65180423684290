
.formButtonsWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.formButtons {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}