@mixin daySelectGroup {
  ul {
    li {
      input:not(:checked) + label {
        padding-left: 17px;
        padding-right: 16px;
      }

      input:checked + label {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }
}
