@use "variables" as *;

$row-height: 50px;
$left-col-width: 25%;
$right-col-width: 75%;

@mixin schedule-list-grid-cell {
  height: $row-height;
  border-bottom: $border;
  &:nth-child(even) {
    background: $grayscale-10-color;
  }
  &:last-child {
    border-bottom: none;
  }
}

@mixin schedule-calendar-grid-cell {
  display: grid;
  grid-template-rows: $row-height;
  @include schedule-list-grid-cell;
}
